import React from 'react'
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import emFwPdf from '../assets/Ecosystem Map Framework (2023).pdf';
import ClimateSpaceIssues from '../assets/ClimateSpaceIssues';
import { useTranslation } from 'react-i18next';

export default function Learn() {

  const [t] = useTranslation();

  return (
    <Container className="section-container">
      <LearnStyle>


        <div className="intro-text">
          {t('DOC/INTRO_TEXT')}
        </div>


        <h3 className="section-subheading">{t('DOC/TOC_HEADING')}</h3>
        <div className="toc-container">
          <div className="text-heading">{t('DOC/TOC_SUBHEADING_1')}</div>
          <a href="#section-1">{t('DOC/SECTION_1_HEADING')}</a>
          <a href="#section-2">{t('DOC/SECTION_2_HEADING')}</a>
          <div className="text-heading">{t('DOC/TOC_SUBHEADING_2')}</div>
          <a href="#section-3">{t('DOC/SECTION_3_HEADING')}</a>
          <a href="#section-4">{t('DOC/SECTION_4_HEADING')}</a>
          <a href="#section-5">{t('DOC/SECTION_5_HEADING')}</a>
          <div className="text-heading">{t('DOC/TOC_SUBHEADING_3')}</div>
          <a href="#section-6">{t('DOC/SECTION_6_HEADING')}</a>
          <a href="#section-7">{t('DOC/SECTION_7_HEADING')}</a>
        </div>






        <h3 id="section-1" className="section-heading">{t('DOC/SECTION_1_HEADING')}</h3>

        <div className="text">
          {t('DOC/SECTION_1_TEXT_1')}
        </div>

        <ClimateSpaceIssues></ClimateSpaceIssues>

        <div className="text">
          {t('DOC/SECTION_1_TEXT_2')}
        </div>

        <ul>
          <li>{t('DOC/SECTION_1_LI_1')}</li>
          <li>{t('DOC/SECTION_1_LI_2')}</li>
          <li>{t('DOC/SECTION_1_LI_3')}</li>
          <li>{t('DOC/SECTION_1_LI_4')}</li>
        </ul>

        <div className="text">
          {t('DOC/SECTION_1_TEXT_3')}
        </div>

        <ol>
          <li>{t('DOC/SECTION_1_LI_5')}</li>
          <li>{t('DOC/SECTION_1_LI_6')}</li>
        </ol>

        <div className="text">
          {t('DOC/SECTION_1_TEXT_4')}
        </div>




        <h3 id="section-2" className="section-heading">{t('DOC/SECTION_2_HEADING')}</h3>

        <div className="text">
          {t('DOC/SECTION_2_TEXT_1')}
        </div>


        <Table bordered>
          <tbody>
            <tr>
              <td className="subheading-td">{t('DOC/SECTION_2_SUBHEADING_1')}</td>
              <td>{t('DOC/SECTION_2_TEXT_2')}</td>
            </tr>
          </tbody>
        </Table>

        <Table bordered>
          <tbody>
            <tr>
              <td className="subheading-td">{t('DOC/SECTION_2_SUBHEADING_2')}</td>
              <td>{t('DOC/SECTION_2_TEXT_3')}</td>
            </tr>
          </tbody>
        </Table>

        <Table bordered>
          <tbody>
            <tr>
              <td className="subheading-td">{t('DOC/SECTION_2_SUBHEADING_3')}</td>
              <td>
                <div className="text-heading first-value-heading">{t('DOC/SECTION_2_VALUE_1')}</div>
                <div className="text">
                  {t('DOC/SECTION_2_VALUE_1_TEXT')}
                </div>

                <div className="text-heading">{t('DOC/SECTION_2_VALUE_2')}</div>
                <div className="text">
                  {t('DOC/SECTION_2_VALUE_2_TEXT')}
                </div>

                <div className="text-heading">{t('DOC/SECTION_2_VALUE_3')}</div>
                <div className="text">
                  {t('DOC/SECTION_2_VALUE_3_TEXT')}
                </div>

                <div className="text-heading">{t('DOC/SECTION_2_VALUE_4')}</div>
                <div className="text">
                  {t('DOC/SECTION_2_VALUE_4_TEXT')}
                </div>
              </td>
            </tr>
          </tbody>
        </Table>





        <h3 id="section-3" className="section-heading">{t('DOC/SECTION_3_HEADING')}</h3>

        <div className="text">
          {t('DOC/SECTION_3_TEXT_1')}
        </div>

        <ol>
          <li>{t('DOC/SECTION_3_LI_1_1')}<a href="#section-4">{t('DOC/SECTION_3_LI_1_2')}</a>{t('DOC/SECTION_3_LI_1_3')}</li>
          <li>{t('DOC/SECTION_3_LI_2_1')}<a href="mailto:mapping@climate2025.org">mapping@climate2025.org</a>{t('DOC/SECTION_3_LI_2_2')}</li>
          <li>{t('DOC/SECTION_3_LI_3')}<a href="mailto:mapping@climate2025.org">mapping@climate2025.org</a>{'.'}</li>
        </ol>



        <h3 id="section-4" className="section-heading">{t('DOC/SECTION_4_HEADING')}</h3>

        <div className="text">
          {t('DOC/SECTION_4_TEXT_1_1')}
          <a href="#section-5">{t('DOC/SECTION_4_TEXT_1_A')}</a>
          {t('DOC/SECTION_4_TEXT_1_2')}
        </div>


        <ol>
          <li>{t('DOC/SECTION_4_LI_1')}</li>
          <li>{t('DOC/SECTION_4_LI_2')}</li>
          <li>{t('DOC/SECTION_4_LI_3')}</li>
          <li>{t('DOC/SECTION_4_LI_4')}</li>
          <li>{t('DOC/SECTION_4_LI_5')}</li>
          <li>{t('DOC/SECTION_4_LI_6')}</li>
          <li>{t('DOC/SECTION_4_LI_7')}</li>
          <li>
            {t('DOC/SECTION_4_LI_8')}
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_1')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_2')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_3')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_4')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_5')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_6')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_7')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_8')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_9')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_10')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_11')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_12')}</span>
            <span className="badge badge-pill">{t('DOC/SECTION_4_BADGE_13')}</span>
          </li>
          <li>{t('DOC/SECTION_4_LI_9')}<a href="mailto:mapping@climate2025.org">mapping@climate2025.org</a>{'.'}</li>
        </ol>








        <h3 id="section-5" className="section-heading">{t('DOC/SECTION_5_HEADING')}</h3>

        <div className="text">
          {t('DOC/SECTION_5_TEXT_1')}
        </div>

        <object className="pdf-container" data={emFwPdf} type="application/pdf" aria-label="Ecosystem Map framework details"></object>







        <h3 id="section-6" className="section-heading">{t('DOC/SECTION_6_HEADING')}</h3>

        <div className="text">
          {t('DOC/SECTION_6_TEXT_1_1')}
          <a href="https://climate2025.org" target="_blank" rel="noopener noreferrer">https://climate2025.org</a>
          {t('DOC/SECTION_6_TEXT_1_2')}
          <a href="mailto:mapping@climate2025.org">mapping@climate2025.org</a>{'.'}

          {t('DOC/SECTION_6_TEXT_2')}
        </div>

        <div className="text-heading">{t('DOC/SECTION_6_TEXT_HEADING_1')}</div>
        <div className="text">{ t('DOC/SECTION_6_VOLUNTEERS_LIST') }</div>

        <div className="text-heading">{t('DOC/SECTION_6_TEXT_HEADING_2')}</div>
        <div className="text">{ t('DOC/SECTION_6_PARTNERS_LIST') }</div>


        <h3 id="section-7" className="section-heading">{t('DOC/SECTION_7_HEADING')}</h3>

        <h5 className="section-subheading">{t('DOC/SECTION_7_SUBHEADING_1')}</h5>
        <div className="text">
          {t('DOC/SECTION_7_TEXT_1_1')}
          <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noopener noreferrer">{t('DOC/SECTION_7_TEXT_1_A')}</a>
          {t('DOC/SECTION_7_TEXT_1_2')}
        </div>

        <h5 className="section-subheading">{t('DOC/SECTION_7_SUBHEADING_2')}</h5>
        <div className="text">
          {t('DOC/SECTION_7_TEXT_2')}
        </div>

        <h5 className="section-subheading">{t('DOC/SECTION_7_SUBHEADING_3')}</h5>
        <div className="text">
          {t('DOC/SECTION_7_TEXT_3')}
        </div>


      </LearnStyle>
    </Container >

  )
}


const LearnStyle = styled.div`
  h2, h3, h4, h5 {
    line-height: 1.5;
    color: var(--primary-color-3);
  }

  a {
    color: var(--primary-color-3);
  }

  li {
    padding: 2px 0;
  }

  table {
    border-color: var(--primary-color-3);
  }

  table:first-of-type {
    margin-top: 10px;
  }

  img {
    width: 100%;

  }

  svg {
    width: 100%;
    max-height: 420px;
    margin: 5px 0 35px 0;
  }

  .section-heading {
    margin: 40px 0 15px 0;
  }
  
  .section-subheading {
    margin: 25px 0 5px 0;
  }

  .pdf-container {
    margin-top: 20px;
    width: 100%;
    height: 500px;
  }

  .text {
    white-space: pre-line;
  }


  .text-heading {
    color: var(--primary-color-3);
    font-weight: 600;
    margin-top: 20px;
  }

  .badge {
    color: var(--primary-color-3);
    background-color: var(--primary-color-3-10);
    margin-right: 3px;
  }

  .toc-container div:first-child {
    margin-top: 0;
  }

  .toc-container a {
    display: block;
    margin-bottom: 4px;
  }

  .toc-items-break {
    margin-top: 15px;
  }

  .subheading-td{
    width: 75px;
    background-color: var(--primary-color-3);
    color: white;
    font-weight: 600;
  }

  .first-value-heading{
    margin-top: 0 !important;
  }

`