import React from 'react'
import Multiselect from '../components/MultiselectDropdown';
import FreeSearch from '../components/FreeSearch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

// Add icons to library
library.add(faCircleInfo);

export default function SelectionSection({ multiselectConfig, freeSearchConfig, updateSelection }) {

    const [t] = useTranslation();

    if (!multiselectConfig || !freeSearchConfig) { return <LoadingDiv>Loading...</LoadingDiv> }
    return (
        <SelectionSectionStyle>
            <Row>
                <Col className="d-flex justify-content-center header-title-line-container">
                    <div className='d-flex justify-content-center header-title-line'>
                        <div className="text">{ t('EXPLORE/ROW_HEADING_1') }</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <p className="info-text">
                   { t('EXPLORE/INFO_TEXT_1') }<i>{ t('EXPLORE/INFO_TEXT_2') }</i>{ t('EXPLORE/INFO_TEXT_3') }<i>{ t('EXPLORE/INFO_TEXT_4') }</i>.
                </p>
                <Col md>
                    <Multiselect
                        config={multiselectConfig[0]}
                        updateSelection={updateSelection}>
                    </Multiselect>
                </Col>
                <Col md>
                    <Multiselect
                        config={multiselectConfig[1]}
                        updateSelection={updateSelection}>
                    </Multiselect>
                </Col>
                <Col md>
                    <Multiselect
                        config={multiselectConfig[2]}
                        updateSelection={updateSelection}>
                    </Multiselect>
                </Col>

            </Row>
            <Row>
                <Col className="d-flex justify-content-center header-title-line-container">
                    <div className='d-flex justify-content-center header-title-line'>
                        <div className="text">{ t('EXPLORE/ROW_HEADING_2') }</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <Multiselect
                        config={multiselectConfig[3]}
                        updateSelection={updateSelection}>
                    </Multiselect>
                </Col>
                <Col md="8">
                    <FreeSearch
                        config={freeSearchConfig}
                        updateSelection={updateSelection}>
                    </FreeSearch>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <Multiselect
                        config={multiselectConfig[4]}
                        updateSelection={updateSelection}>
                    </Multiselect>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center header-title-line-container">
                    <div className='d-flex justify-content-center header-title-line'>
                        <div className="text">{ t('EXPLORE/ROW_HEADING_3') }</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <Multiselect
                        config={multiselectConfig[5]}
                        updateSelection={updateSelection}>
                    </Multiselect>
                </Col>
                <Col md>
                    <Multiselect
                        config={multiselectConfig[6]}
                        updateSelection={updateSelection}>
                    </Multiselect>
                </Col>
                <Col md>
                    <Multiselect
                        config={multiselectConfig[7]}
                        updateSelection={updateSelection}>
                    </Multiselect>
                </Col>
            </Row>
        </SelectionSectionStyle>

    )
}

const SelectionSectionStyle = styled.div`
    margin-top: 10px;
    
    [class*="col-md"] {
        margin-bottom: 25px;
    }

    .info-text {
        svg {
            margin-right: 5px;
        }
    }

    .header-title-line-container {
        padding: 30px 0 30px 0;
    }

    .header-title-line {
        border-top: 1px solid var(--primary-color-1);
        width: 90%;
    }

    .header-title-line .text {
        color: var(--primary-color-1);
        position: absolute;
        margin-top: -13px;
        background-color: white;
        padding: 0px 10px;
    }
`

const LoadingDiv = styled.div`
    font-weight: 500;
    text-align: center;
    font-size: var(--l-heading-font-size);
    margin-top: 35px;
`
