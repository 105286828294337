import React from 'react'
import JotformEmbed from 'react-jotform-embed';
import Modal from 'react-bootstrap/Modal';

export default function FeedbackModal({ show, handleClose }) {
    return (
        <Modal className="feedback-modal" show={show} fullscreen={true} onHide={handleClose}>

            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <JotformEmbed src="https://form.jotform.com/223034278173352" />
            </Modal.Body>

        </Modal>
    )
}

