import React from 'react'
import JotformEmbed from 'react-jotform-embed';
import styled from 'styled-components';

export default function Contribute() {
  return (
    <ContributeStyle>
        <JotformEmbed src="https://form.jotform.com/223003051937042" /> 
    </ContributeStyle>
  )
}

const ContributeStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  iframe {
    flex: 1;
    min-height: 600px;
  }
`