import _ from 'lodash';
import { shuffleArray } from './data-processing-functions.js';

export function setCssDefaultColors (arrayColors) {

    // Create an array with number from 0 to the length of arrayColors and sort it randomly
    let orderColors = shuffleArray(_.range(0, arrayColors.length));

    // Get the root element
    var rootElement = document.querySelector(':root');

    orderColors.forEach( function (ri, i) {
        rootElement.style.setProperty(`--primary-color-${ri + 1}-rgb`, `var(${arrayColors[i]})`);
    })
}