import React from 'react'
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import { NavLink, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";


export default function NavigationBar() {

    let location = useLocation();

    React.useEffect(() => {
        // Google Analytics: Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname.slice(1) });
    }, [location]);

    return (
        <NavbarStyle>
            <Container>
                <nav className="navbar navbar-expand d-flex justify-content-start">
                    <div>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/explore">EXPLORE</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/contribute">CONTRIBUTE</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/learn">LEARN</NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
            </Container>
        </NavbarStyle>
    )
}

const NavbarStyle = styled.div`
    .navbar {
        padding: 2px 0 0 0;
    }

    .navbar-nav .nav-link {
        font-size: var(--heading-font-size);
        font-weight: 500;
    }

    .navbar-nav .nav-link.active {
        font-weight: 600;
    }

    .navbar-nav .nav-link:hover {
        text-decoration: none;
    }


    .navbar-nav li {
        padding-right: 34px;
    }

    .navbar-nav li a {
        padding: 0px !important;
        color: var(--primary-color-dark);
        font-weight: 600;
        text-align: center;
    }

    .navbar-nav li:nth-child(1) a:hover {
        color: var(--primary-color-1);
    }

    .navbar-nav li:nth-child(2) a:hover {
        color: var(--primary-color-2);
    }

    .navbar-nav li:nth-child(3) a:hover {
        color: var(--primary-color-3);
    }

    .navbar-nav li:nth-child(1) a.active {
        color: var(--primary-color-1);
    }

    .navbar-nav li:nth-child(2) a.active {
        color: var(--primary-color-2);
    }

    .navbar-nav li:nth-child(3) a.active {
        color: var(--primary-color-3);
    }


    /* Very small screens */
    @media (max-width: 300px) {
        .navbar {
            padding: 4px 0 0 0;
        }

        .navbar-nav .nav-link {
            font-size: var(--heading-font-size)*0.9;
        }
        
        .navbar-nav li {
            padding-right: 15px;
        }
    }


    @media (min-width: 300px) and (max-width: 550px) {
        .navbar {
            padding: 4px 0 0 0;
        }

        .navbar-nav .nav-link {
            font-size: var(--heading-font-size)*0.9;
        }

        .navbar-nav li {
            padding-right: 28px;
        }
    }    
`


