import React, { useState } from 'react'
import FeedbackModal from '../components/FeedbackModal';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import invertedClimateStripes from '../assets/Climate_stripes_inverted.png';

export default function Home() {

  const [t] = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <Container className="section-container">
      <HomeStyle>
      
        <h2 className="title">{ t('HOME/TITLE') }</h2>

        <h3 className="subtitle">{ t('HOME/SUBTITLE') }</h3>

        <div className="text">
          { t('HOME/TEXT_1') }
          <Link to="explore">{ t('HOME/LINK_1')}</Link>
          { t('HOME/TEXT_2') }
          <Link to="contribute">{ t('HOME/LINK_2' ) }</Link>
          { t('HOME/TEXT_3') }
          <Link to="learn">{ t('HOME/LINK_3') }</Link>
          { t('HOME/TEXT_4') }
          <div className="link-styled a-styled" onClick={handleShow}>{ t('HOME/A_1') }</div>
          { t('HOME/TEXT_5') }
        </div>
        
      </HomeStyle>
      <FeedbackModal show={showModal} handleClose={handleClose}></FeedbackModal>
    </Container>
  )
}

const HomeStyle = styled.div`

  h2, h3, h4, h5 {
    line-height: 1.5;
  }

  .title {
    margin: 0 0 25px 0;
  }

  .subtitle {
    margin-bottom: 30px;
    color: var(--primary-color-2);
  }


  .climate-stripes {
    height: 3px;
    width: 100%;
    object-fit: cover;
  }

  .text {
    margin-top: 5px;
    white-space: pre-line;
  }

  @media (min-width: 996px) {
    h4 {
      margin-top: 10px;
    }
}
`
