import React  from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components';

// Add icons to library
library.add(faCircleInfo);

export default function MultiselectDropdown({ config, updateSelection }) {

    const onSelect = (selectedList, selectedItem) => {
        updateSelection({[config.field]: selectedList});
    }

    const onRemove = (selectedList, removedItem) => {
        updateSelection({[config.field]: selectedList});
    }

    const renderTooltip = (text) => (
        <Tooltip id="multiselect-tooltip">
            {text}
        </Tooltip>
    );

 

    return (
        <MultiselectDropdownStyle>
            <div className="d-flex justify-content-between">
                <div className="multiselect-title">{config.title}</div>
                <div className="multiselect-tooltip">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 50 }}
                        overlay={renderTooltip(config.tooltipText)}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-circle-info" />
                    </OverlayTrigger>
                </div>
            </div>
            <Multiselect
                options={config.options} // Options to display in the dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder="Select or type criteria"
            />
        </MultiselectDropdownStyle>


    )
}

const MultiselectDropdownStyle = styled.div`
    .multiselect-title {
        font-weight: 500;
    }

    .multiselect-tooltip svg path {
        fill: black;
    }

    .tooltip {
        background-color: red;
    }

    .search-wrapper {
        border: 1px solid rgb(32 54 101 / 0%);
        border-radius: 2px;
        background-color: var(--primary-color-1-10);
    }

    input {
        cursor: pointer;
        width: 100%;
    }

    .chip {
        background: var(--primary-color-1);
        border-radius: 3px;
    }
`