import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import _ from 'lodash';
import { retrieveMultiselectOptions, retrieveFreeSearchDataAndOptions } from '../utils/data-processing-functions.js';
import SelectionSection from '../sections/SelectionSection';
import ResultsSection from '../sections/ResultsSection';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

// Add icons to library
library.add(faMagnifyingGlass);

export default function Explore() {

    const [t] = useTranslation();

    const [data, setData] = useState([]),
        [multiselectConfig, setMultiselectConfig] = useState(undefined),
        [freeSearchConfig, setFreeSearchConfig] = useState(undefined),
        [globalSelection, setGlobalSelection] = useState({}),
        [selection, setSelection] = useState({}),
        [resultsSectionDisabled, setResultsSectionDisabled] = useState(true),
        [updateDate, setUpdateDate] = useState(undefined);

    // Retrieve data and multiselect config
    const dataPromise = fetch('data/map-data.json'),
          multiselectConfigPromise = fetch('config/search-config.json'),
          updateDateData = fetch('data/map-data-date.json');

    const fetchData = () => {
        Promise.all([
            dataPromise,
            multiselectConfigPromise,
            updateDateData
        ]).then(responses => Promise.all(responses.map(r => r.clone().json())
        )).then(values => {

            const multiselectOptions = retrieveMultiselectOptions(
                values[0],
                _.filter(values[1], d => d.id < 100)
            )

            const { contentData, freeSearchConfig } = retrieveFreeSearchDataAndOptions(
                values[0],
                _.last(values[1]),
                { "keywords": ".,:;·&|" }
            )

            setData(contentData);
            setMultiselectConfig(multiselectOptions);
            setFreeSearchConfig(freeSearchConfig);
            setUpdateDate(new Date(values[2][0]['date']));

        }).catch((error) => {
            console.log(error);
        });

    }

    const showResults = () => {
        // When we click the "Search for organisations" shows the Results section
        setResultsSectionDisabled(false);
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        // Hide the Results section when a new selection is made
        setResultsSectionDisabled(true);

        // Update global selection with the new selection
        let newGlobalSelection = { ...globalSelection };

        _.forOwn(selection, (v, k) => {
            if (v.length > 0) {
                newGlobalSelection[k] = _.map(v, 'name');
            } else {
                delete newGlobalSelection[k];
            }

        })

        setGlobalSelection(newGlobalSelection);

    }, [selection]);

    return (
        <ExploreStyle>
            <Container className="section-container">
                <div className="intro-text">
                    { t('EXPLORE/INTRO_TEXT')}
                    <Link to="/contribute">{ t('EXPLORE/CONTRIBUTE_LINK') }{'.'}</Link>
                </div>
                <h3>{ t('EXPLORE/HEADING_WHAT_KIND') }</h3>
                <p>{ t('EXPLORE/P_SELECT') }</p>
                <SelectionSection
                    multiselectConfig={multiselectConfig}
                    freeSearchConfig={freeSearchConfig}
                    updateSelection={setSelection}>
                </SelectionSection>
                <Button className="explore-button search" onClick={showResults} variant="primary" size="lg">
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                    { t('EXPLORE/BUTTON_SEARCH') }
                </Button>
            </Container>
            <ResultsSection
                selection={globalSelection}
                data={data}
                disabled={resultsSectionDisabled}
                updateDate={updateDate}
            ></ResultsSection>
        </ExploreStyle>
    )
}


const ExploreStyle = styled.div`
    h1, h2, h3, h4 {
        margin-top: 40px;
    }

    .intro-text {
        white-space: pre-line;
    }

    .explore-button {
        svg {
            margin-right: 10px;
            font-size: 25px;
        }

        display: flex;
        align-items: center;

        background-color: white;
        border-radius: 5px;
        padding: 17px 17px;
        
        border: 1px solid var(--primary-color-1);
        
        font-size: 15px;
        font-weight: 500;
        color: var(--primary-color-1);
    }

    .explore-button:hover {
        background-color: var(--primary-color-1);
        color: white;
    }

    .explore-button.search {
        margin: 30px 0 80px 0;
    }

    .explore-button.download {
        margin: 20px 20px 0 0;
        
    }
`

