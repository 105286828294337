import React, { useState } from 'react'
import styled from 'styled-components';
import Logo from '../assets/Logo.png';
import Climate2025Logo from '../assets/Climate_2025_Logo_New_White.png';
import FeedbackModal from './FeedbackModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faComment } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

// Add icons to library
library.add(faEnvelope, faComment);

export default function Footer() {

    const [t] = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <FooterStyle>
            <footer>
                <div className="d-flex justify-content-between">
                    <div className="left-container d-flex justify-content-center flex-column">
                        <a className="license-link" rel="license noopener noreferrer" target="_blank" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">
                            <img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-sa/4.0/80x15.png" />
                        </a>
                        <div className="license-text">
                            { t('FOOTER/LICENSE_TEXT_1') }
                            <a rel="license noopener noreferrer" target="_blank" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">
                            { t('FOOTER/LICENSE_TEXT_2') }
                            </a>
                        </div>

                    </div>
                    <div className="center-container container d-flex flex-column align-items-center justify-content-center flex-md-row justify-content-md-between">
                        <div className="text site-logo">
                            <div className="d-flex justify-content-start align-items-center">
                                <img
                                    src={Logo}
                                    className="logo d-inline-block align-top"
                                    alt="Platform logo"
                                />
                                <div className="upper">Ecosystem Map</div>
                            </div>
                            <div className="lower">{new Date().getFullYear()}</div>
                        </div>
                        <div className="powered-by d-flex justify-content-end flex-column flex-md-row align-items-center">
                            <div className="text">{ t('FOOTER/POWERED_BY') }</div>
                            <a href="https://www.climate2025.org/" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={Climate2025Logo}
                                    className="d-inline-block align-top"
                                    alt="Climate 2025 logo"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="right-container d-flex flex-column justify-content-center">
                        <div className="footer-email">
                            <FontAwesomeIcon icon="fa-solid fa-envelope" />
                            <a href="mailto:mapping@climate2025.org">{ t('FOOTER/CONTACT_US') }</a>
                        </div>
                        <div className="footer-feedback">
                            <FontAwesomeIcon icon="fa-solid fa-comment" />
                            <div className="link-styled a-styled" onClick={handleShow}>{ t('FOOTER/FEEDBACK') }</div>
                        </div>
                    </div>
                </div>
            </footer>
            <FeedbackModal show={showModal} handleClose={handleClose}></FeedbackModal>
        </FooterStyle>
    )
}

const FooterStyle = styled.div`
    footer {
        width: 100%;
        background-color: var(--secondary-color);
        padding: 15px 0px;
    }

    .powered-by img {
        width: 75px;
        height: auto;
        padding-right: 5px;
    }

    .powered-by .text {
        color: white;
        font-size: var(--s-text-font-size) !important;
        line-height: 0.5 !important;
        padding-right: 5px;
    }

    .text {
        color: white;
        line-height: 125%;
    }

    .text .upper {
        font-weight: 600;
        font-size: var(--text-font-size);
    }

    .logo {
        height:  calc(var(--text-font-size) * 0.75);
        padding-right: 2px;
    }

    .left-container {
        min-width: 175px;
        max-width: 200px;
        padding: 0 10px 0 35px;
    }

    .right-container {
        min-width: 175px;
        max-width: 200px;
        padding: 0 35px 0 10px;
    }

    .right-container div, .right-container a, .right-container .link-styled {
        color: white;
        font-size: var(--s-text-font-size) !important;
    }

    .right-container svg {
        margin-right: 5px;
    }

    .license-link img {
        padding: 4px 0px;
    }

    .left-container div, .left-container a {
        color: white;
        font-size: var(--xxs-text-font-size);
    }

    .license-text {
        font-size: var(--xxs-text-font-size) !important;
    }

    /* Very small screens */
    @media (max-width: 300px) {
        .left-container {
            min-width: 100px;
            padding: 0 0 0 5px;
        }
    
        .right-container {
            min-width: 100px;
            padding: 0 5px 0 0;
        }

        .center-container {
            width: 200px;
        }

        .right-container a, .right-container svg, .right-container .link-styled {
            font-size: var(--xs-text-font-size) !important;
        }
    }

    @media (min-width: 300px) and (max-width: 550px) {
        .left-container {
            min-width: 100px;
            padding: 0 5px 0 10px;
        }
    
        .right-container {
            min-width: 100px;
            padding: 0 10px 0 5px;
        }
            
        .center-container {
            width: 200px;
        }

        .right-container a, .right-container svg, .right-container .link-styled {
            font-size: var(--xs-text-font-size) !important;
        }

    }

    @media (max-width: 768px) {
        .left-container {
            width: 130px;
            padding-left: 15px;
        }

        .powered-by .text {
            padding-bottom: 10px;
        }

        .site-logo {
            padding-bottom: 10px;
        }

        .lower {
            display: none;
        }
    }
}

`

