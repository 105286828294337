import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import _ from 'lodash';
import xlsx from 'json-as-xlsx';
import csvDownload from 'json-to-csv-export'
import { selectionFilterData } from '../utils/data-processing-functions.js';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faCloudArrowDown, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faInstagram, faTiktok, faYoutube, faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';


// Add icons to library
library.add(faCloudArrowDown, faTriangleExclamation, faEnvelope, faTwitter, faInstagram, faTiktok, faYoutube, faFacebookSquare, faLinkedin);

export default function ResultsSection({ selection, data, disabled, updateDate }) {

    const [t] = useTranslation();

    const [selectionData, setSelectionData] = useState(undefined);

    // EXPORT COLUMNS
    const exportColumns = ['name', 'country', 'org_website', 'twitter_link', 'instagram_link'];

    const socialNetworksCardLinks = [
        { 'id': 'public_email', 'fa_class': 'fa-regular fa-envelope' },
        { 'id': 'facebook_link', 'fa_class': 'fa-brands fa-facebook-square' },
        { 'id': 'instagram_link', 'fa_class': 'fa-brands fa-instagram' },
        { 'id': 'linked_in_link', 'fa_class': 'fa-brands fa-linkedin' },
        { 'id': 'twitter_link', 'fa_class': 'fa-brands fa-twitter' },
        { 'id': 'tik_tok_link', 'fa_class': 'fa-brands fa-tiktok' },
        { 'id': 'you_tube_link', 'fa_class': 'fa-brands fa-youtube' }
    ]

    useEffect(() => {
        setSelectionData(
            selectionFilterData(data, selection)
        )
    }, [selection, disabled]);

    const downloadCSV = () => {
        const csvSettings = {
            data: _.map(selectionData, _.partialRight(_.pick, exportColumns)),
            filename: 'Ecosystem Map organisations',
            delimiter: ',',
            headers: exportColumns.map(c => _.startCase(c))
        }

        csvDownload(csvSettings);
    }

    const downloadXLSX = () => {
        const settings = {
            fileName: "Ecosystem Map organisations",
            extraLength: 3
        },
            xlsxData = [{
                sheet: "Organisations",
                columns: exportColumns.map(c => {
                    return { label: _.startCase(c), value: c }
                }),
                content: selectionData
            }];

        xlsx(xlsxData, settings);
    }

    return !disabled && (
        <ResultsSectionStyle>
            <Container fluid className="light-background">
                <Container>
                    <div className="num-found">{ t('EXPLORE/WE_FOUND') }{' '}
                        <div className="highlight">{selectionData ? selectionData.length : 0}</div>{' '}
                        { t('EXPLORE/ORGANISATIONS') }
                    </div>
                    <h3>{ t('EXPLORE/HEADING_EXPLORE') }</h3>
                    <p>{ t('EXPLORE/P_EXPLORE_OPTIONS') }</p>
                    <div className="d-flex align-items-center flex-column align-items-md-start flex-md-row">
                        <Button className="explore-button download" onClick={downloadCSV} variant="primary" size="lg">
                            <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-down" />
                            { t('EXPLORE/DOWNLOAD_CSV') }
                        </Button>
                        <Button className="explore-button download" onClick={downloadXLSX} variant="primary" size="lg">
                            <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-down" />
                            { t('EXPLORE/DOWNLOAD_EXCEL') }
                            
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container>
                <div className="card-container">
                    <p className="limit-warning" style={{ display: (selectionData || []).length >= 100 ? undefined : 'none' }}>
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                        { t('EXPLORE/ONE_HUNDRED_ONLY') }
                    </p>
                    <Row xs={1} md={4}>
                        {selectionData ? selectionData.slice(0, 100).map(obj => (
                            <Col key={obj['name']}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            {obj['name']}
                                        </Card.Title>
                                        <Card.Subtitle className={`mb-2 text-muted ${ (_.includes(obj['main_purpose_approach'] || [], 'Investment and fundraising') ? 'funder' : undefined) }`}>
                                            {obj['country']}
                                        </Card.Subtitle>
                                        <div className="funder-tag" style={{ display: (_.includes(obj['main_purpose_approach'] || [], 'Investment and fundraising')) ? undefined : 'none' }}>
                                            { t('EXPLORE/FUNDER_TAG') }
                                        </div>
                                        <div className="card-text">
                                            <div className="category-container" style={{ display: (selection['organisation_type'] || []).length !== 1 ? undefined : 'none' }}>
                                                <div className="category-title">{ t('EXPLORE/CATEGORY_TITLE_1') }</div>
                                                {obj['organisation_type']}
                                            </div>
                                            <div className="category-container pre-line-container" style={{ display: (obj['main_purpose_approach'] || []).length > 0 ? undefined : 'none' }}>
                                                <div className="category-title">{ t('EXPLORE/CATEGORY_TITLE_2') }</div>
                                                {(_.isNull(obj['main_purpose_approach']) ? '' : obj['main_purpose_approach'].replaceAll("|", "\n"))}
                                            </div>
                                        </div>
                                        <Card.Link style={{ display: obj['org_website'] ? undefined : 'none' }} href={obj['org_website']} target="_blank">
                                            { t('EXPLORE/CARD_WEBSITE_LINK') }
                                        </Card.Link>
                                        {
                                            socialNetworksCardLinks.map(snLink => (
                                                <Card.Link className="media-logo" style={{ display: obj[snLink.id] ? undefined : 'none' }} href={snLink.id === 'public_email' ? `mailto:${obj[snLink.id]}` : obj[snLink.id]} target="_blank">
                                                    <FontAwesomeIcon icon={`${snLink.fa_class}`} />
                                                </Card.Link>
                                            ))
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        )) : <div></div>}
                    </Row>
                </div>
                <div className="update-date-container">
                { t('EXPLORE/DATE_UPDATE') }{updateDate.toLocaleString(i18n.language, {  year: 'numeric', month: 'long', day: 'numeric' })}
                </div>
            </Container>
        </ResultsSectionStyle>
    )
}

const ResultsSectionStyle = styled.div`
    .num-found {
        font-size: var(--l-heading-font-size) !important;
        font-weight: 500;
        color: var(--primary-color-dark);
        margin-bottom: -32px;
    }

    .highlight {
        display: inline-block;
        font-size: var(--l-heading-font-size)  !important;
        font-weight: 700;
    }

    .light-background {
        padding: 80px 0 60px 0;
        background-color: var(--primary-color-1-10)
    }

    .card-container {
        margin: 50px 0 30px 0;
        padding-right: 20px;
        max-height: 700px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .update-date-container {
        margin-bottom: 40px;
        font-size: var(--xxs-text-font-size);
        font-style: italic;
    }

    .card-body .mb-2 {
        margin-bottom: 1rem !important;
    }
    
    .limit-warning {
        font-weight: 500;
    }
    
    .limit-warning svg{
        margin-right: 5px;
        font-size: 15px; 
        color: var(--secondary-color);
    }

    .card {
        border: 1px solid var(--primary-color-1);
        border-radius: 3px;
        background-color: white;
        margin-bottom: 30px;
    }

    .card-title {
        color: var(--primary-color-1);
        font-size: var(--heading-font-size);
        font-weight: 600;
    }
    
    .funder-tag {
        display: inline-block;
        border: 1px solid var(--primary-color-3);
        background-color: var(--primary-color-3);
        color: white;
        padding: 0 5px;
        margin-bottom: 0.5rem;
        margin-right: 5px; 
        border-radius: 3px;
    }

    .card-subtitle{
        color: var(--primary-color-2) !important;
        font-size: var(--text-font-size);
    }
    
    .card-subtitle.funder {
        margin-bottom: 12px !important;
    }

    .card-text {
        margin-bottom: 1rem;
    }

    .category-container {
        font-size: var(--xs-text-font-size) !important;
    }

    .pre-line-container {
        white-space: pre-line;
    }

    .category-title {
        font-size: var(--s-text-font-size)  !important;
        font-weight: 500;
        margin-top: 8px;
    }

    .card-link {
        color: var(--primary-color-2);
        margin-left: 0;
        margin-right: var(--bs-card-spacer-x);
    }

    .card-link .media-logo {
        text-decoration: none !important;
    }
`

