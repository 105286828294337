import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/Home';
import Explore from './pages/Explore';
import Contribute from './pages/Contribute';
import Learn from './pages/Learn';
import TopBar from './components/TopBar';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import LogIn from './components/LogIn';
import { setCssDefaultColors } from './utils/styling-functions.js'
import ReactGA from 'react-ga4';
import styled from 'styled-components';

export default function App() {

  setCssDefaultColors(['--primary-green-color-rgb', '--primary-yellow-color-rgb', '--primary-blue-color-rgb']);

  const [isVerified, setIsVerified] = useState(false);

  return (
    <>
      <AppContainer>
        <Router>
          {(isVerified ? < TopBar /> : <></>)}
          {(isVerified ? < NavigationBar /> : <></>)}
          <Routes>
            <Route exact path="/" element={(isVerified ? <Home /> : <LogIn setIsVerified={setIsVerified}></LogIn>)} />
            <Route path="explore" element={(isVerified ? <Explore /> : <LogIn setIsVerified={setIsVerified}></LogIn>)} />
            <Route path="contribute" element={(isVerified ? <Contribute /> : <LogIn setIsVerified={setIsVerified}></LogIn>)} />
            <Route path="learn" element={(isVerified ? <Learn /> : <LogIn setIsVerified={setIsVerified}></LogIn>)} />
          </Routes>
        </Router>
      </AppContainer>
      {(isVerified ? < Footer /> : <></>)}
    </>
  )

}

// Intialise Google Analytics measurement
ReactGA.initialize("G-N4YYWB9411");


const AppContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  `