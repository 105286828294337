import React from 'react'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/Logo.png';
import BetaLabel from '../assets/beta_label.png';
import styled from 'styled-components';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faComment } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';


// Set up languages
const lngsConfig = [
    { id: 'en', name: 'English' }
    //{ id: 'es', name: 'Castellano' }
];


// Add icons to library
library.add(faComment);

export default function TopBar() {
    const { i18n } = useTranslation();

    return (
        <TopbarStyle>
            <Container>
                <div className="d-flex justify-content-between">
                    <Navbar>
                        <Navbar.Brand>
                            <Link to="/" className="p-0 d-flex align-items-center">
                                <img
                                    className="d-inline-block em-logo align-top"
                                    src={Logo}
                                    alt="Ecosystem Map logo"
                                ></img>
                                Ecosystem Map
                                <img
                                    className="beta-label"
                                    src={BetaLabel}
                                    alt="Beta tag"
                                ></img>
                            </Link>
                        </Navbar.Brand>
                    </Navbar>
                    <div className="d-flex right-container align-items-end">
                        <Dropdown>
                            <Dropdown.Toggle>
                                {_.find(lngsConfig, o => o.id === i18n.resolvedLanguage).name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {lngsConfig.map(
                                    lng => <Dropdown.Item onClick={() => i18n.changeLanguage(lng.id)} key={lng.id}>{lng.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Container>
        </TopbarStyle>

    )
}

const TopbarStyle = styled.div`
    padding-top: 66px;
    padding-bottom: 18px;

    .navbar .em-logo {
        height:  calc(var(--title-font-size) * 0.75);
        padding-right: 5px;
    }


    .navbar .beta-label {
        display: inline;
        transform: rotate(0.2rad);
        height: calc(var(--title-font-size) * 0.6) !important;
        margin-top: calc(var(--title-font-size) * -1.2);
        margin-left: calc(var(--title-font-size) * -0.65)
    }

    .navbar a {
        text-decoration: none;
        line-height: 0;
    }

    .navbar {
        padding: 0;
    }

    .navbar-brand a {
        color: var(--primary-color-dark);
        font-weight: 500;
        font-size: var(--title-font-size);
    }

    .right-container div, .right-container a {
        color: var(--primary-color-1);
    }

    .right-container svg {
        margin-right: 5px;
    }

    .right-container .dropdown {
        padding-bottom: 5px;
    }

    .right-container .dropdown button {
        border: 0px;
        background-color: white;
        color: var(--primary-color-1);
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }

    .right-container .dropdown-menu {
        border: 1px solid var(--primary-color-1);
        border-radius: 2px;
    }

    .top-bar-feedback a {
        cursor: pointer;
    }


    /* Very small screens */
    @media (max-width: 300px) {
        .navbar-brand a {
            font-size: calc(var(--title-font-size) * 0.4);
        }

        .navbar .em-logo {
            height:  calc(var(--title-font-size) * 0.4 * 0.75);
        }

        .navbar .beta-label {
            height: calc(var(--title-font-size) * 0.4 * 0.6) !important;
            margin-top: calc(var(--title-font-size) * 0.4 * -1.2);
            margin-left: calc(var(--title-font-size) * 0.4 * -0.65);
        }


        .right-container .dropdown {
            padding-bottom: 0;
        }
    }

    @media (min-width: 300px) and (max-width: 550px) {
        .navbar-brand a {
            font-size: calc(var(--title-font-size) * 0.55);
        }

        .navbar .em-logo {
            height:  calc(var(--title-font-size) * 0.55 * 0.75);
        }

        .navbar .beta-label {
            height: calc(var(--title-font-size) * 0.55 * 0.6) !important;
            margin-top: calc(var(--title-font-size) * 0.55 * -1.2);
            margin-left: calc(var(--title-font-size) * 0.55 * -0.65)
        }
    }

    @media (min-width: 550px) and (max-width: 768px) {
        .navbar-brand a {
            font-size: calc(var(--title-font-size) * 0.75);
        }

        .navbar .em-logo {
            height:  calc(var(--title-font-size) * 0.75 * 0.75);
        }

        .navbar .beta-label {
            height: calc(var(--title-font-size) * 0.75 * 0.6) !important;
            margin-top: calc(var(--title-font-size) * 0.75 * -1.2);
            margin-left: calc(var(--title-font-size) * 0.75 * -0.65)
        }
    }


    @media (min-width: 768px) and (max-width: 900px) {
        .navbar-brand a {
            font-size: calc(var(--title-font-size) * 0.9);
        }

        .navbar .em-logo {
            height:  calc(var(--title-font-size) * 0.9 * 0.75);
        }


        .navbar .beta-label {
            height: calc(var(--title-font-size) * 0.9 * 0.6) !important;
            margin-top: calc(var(--title-font-size) * 0.9 * -1.2);
            margin-left: calc(var(--title-font-size) * 0.9 * -0.65)
        }
    }


    
`
