import _ from 'lodash';

export function retrieveMultiselectOptions(data, config, optionsField = 'options') {
    return _.map(config, (fieldConfig) => {
        let fieldValues;
        if (fieldConfig['sep']) {
            let fieldValuesNested = _.map(data, (d) => {
                return _.split(d[fieldConfig['field']], fieldConfig['sep'])
            });
            fieldValues = _.flatten(fieldValuesNested);
        } else {
            fieldValues = _.map(data, fieldConfig['field']);
        }

        fieldConfig[optionsField] = _.chain(fieldValues)
            .filter(v => !_.isNull(v))
            .filter(v => v.length > 0 )
            .orderBy()
            .uniqBy()
            .map((o, i) => {
                return { id: i, name: o };
            })
            .value();

        return fieldConfig;
    });
}

export function retrieveFreeSearchDataAndOptions(data, config, fieldsConfig, optionsField = 'options') {
    let contentData = data.map(d => {
        let dNestedValues = _.keys(fieldsConfig).map(field => {
            return (!_.isNull(fieldsConfig[field]) ?
                _.split(d[field], new RegExp("[" + fieldsConfig[field] + "]+", "i")) :
                [d[field]]);
        });

        let dExportValues = _.chain(dNestedValues)
            .flatten()
            .filter(v => !_.isNull(v))
            .filter(v => v.length > 0 )
            .uniqBy()
            .map(v => v.trim().toLowerCase())
            .value()

        d[config['field']] = _.join(dExportValues, config['sep']);

        return d;
    });

    // Build free search config based on new content column
    let contentValues = _.map(contentData, config['field']);

    config[optionsField] = _.chain(contentValues)
        .map(s => s.split(config['sep']))
        .flatten()
        .uniqBy()
        .map((o, i) => {
            return { id: i, name: o };
        })
        .value();

    return {
        contentData: contentData,
        freeSearchConfig: config
    };
}

export function selectionFilterData(data, selection) {
    return _.filter(data, obj => {
        return _.every(
            _.map(_.keys(selection), field => {
                return _.some(
                    selection[field].map(filterValue => _.includes(obj[field], filterValue))
                )
            })
        )
    })


}

export function shuffleArray (array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}